import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import '../Portfolio1.css'; 

const TabSix = [
    {
        image: 'saeeduau',
        bigImage: '../images/portfolio1/main/dp-portfolio-saeeduau.png',
        category: 'Wordpress',
        websiteName:'SAE CREATIVE MEDIA INSTITUTE',
        title: 'FOR YOUR CREATIVE CAREER BEGINS NOW',
		siteurl:'https://sae.edu.au/'
    },
    {
        image: 'balajimariline',
        bigImage: '../images/portfolio1/main/dp-portfolio-balajimariline.png',
        category: 'Wordpress',
        websiteName:'BALAJIMARILINE',
        title: 'servicing the Indian and foreign exporters and Importers',
		siteurl:'https://www.balajimariline.com/'
    },
    {
        image: 'ellasbubbles',
        bigImage: '../images/portfolio1/main/dp-portfolio-ellasbubbles.png',
        category: 'Wordpress',
        title: 'An ecommerce website',
        websiteName:'ELLASBUBBLES',
		siteurl:'https://ellasbubbles.com/'
    },
    {
        image: 'mensfitclub',
        bigImage: '../images/portfolio1/main/dp-portfolio-mensfitclub.png',
        category: 'Wordpress',
        websiteName:'MENSFITCLUB',
        title: 'This is related about health and gym',
		siteurl:'https://www.mensfitclub.com/'
    },
    {
        image: 'wla',
        bigImage: '../images/portfolio1/main/dp-portfolio-wla.png',
        category: 'Wordpress',
        websiteName:'WLA',
        title: 'This is related about Women Leadership Australia',
		siteurl:'https://www.wla.edu.au/'
    },
]

class Wordpress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab6: 0,
            isOpen: false,
        };
    }
    render() {
        const { tab6, isOpen } = this.state;
        return (
           <>
           <div className="row row--35">
                        {TabSix.map((value , index) => (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab6].bigImage}
                                        nextSrc={TabSix[(tab6 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab6 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab6: (tab6 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab6: (tab6 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab6: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnails">
                                                    <a href="#portfolio-details">
                                                        <img src={`../images/portfolio1/main/dp-portfolio-${value.image}.png`} alt="Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p><a href={value.siteurl} target="_blank" rel="noopener noreferrer">{value.websiteName}</a></p>
                                                    <h4>{value.title}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
           </>
        )
    }
}
export default Wordpress;