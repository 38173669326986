import React, { Component } from 'react'
import 'react-image-lightbox/style.css';
import { NavLink, Outlet } from "react-router-dom";
import './Portfolio1.css'; 


class TabStyleThree extends Component {

    render() {
        return (
            <div>
            <section  data-id="portfolio">
              <div className="border-block-top-110"></div>
              <div className="section-inner">
                <div className="section-title-block">
                  <div className="section-title-wrapper">
                    <h2 className="section-title">Portfolio</h2>
                    <h5 className="section-description">My Best Works</h5>
                  </div>
                </div>
                <div>
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <div className="tablist-inner">
                                <ul className="pv-tab-button text-center mt--0">
                                    <li><NavLink className="pt-trigger"  activeClassName="is-active" to="/portfolio"><span>All Project</span></NavLink></li>
                                    <li><NavLink className="pt-trigger"  activeClassName="is-active" to="/portfolio/magento2" exact><span>Magento2</span></NavLink></li>
                                    <li><NavLink className="pt-trigger"  activeClassName="is-active" to="/portfolio/php" exact><span>PHP</span></NavLink></li>
                                    <li><NavLink className="pt-trigger"  activeClassName="is-active"to="/portfolio/laravel" exact><span>Laravel</span></NavLink></li>
                                    <li><NavLink className="pt-trigger"  activeClassName="is-active" to="/portfolio/codeigniter" exact><span>CodeIgniter</span></NavLink></li>
                                    <li><NavLink className="pt-trigger"  activeClassName="is-active" to="/portfolio/wordpress" exact><span>Wordpress</span></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Outlet />
                </div>
                </div>
            </section>
            </div>
        )
    }
}


export default TabStyleThree
