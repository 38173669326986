import { useState, useEffect } from "react";
import axios from 'axios';
import EmptyList from '../../components/common/EmptyList';
import BlogList from '../../components/Home/BlogList';
import SearchBar from '../../components/Home/SearchBar';

const BlogHome = () => {
  const [blogs, setBlogs] = useState([]);
  
  const getApiData = async () => {
        const res = await axios.get("https://api.pluxinfosystems.com/wp-json/wp/v2/posts");
        setBlogs(res.data);
    };

    useEffect(() => {
      getApiData();
    }, []);
   
  const [searchKey, setSearchKey] = useState('');

  // Search submit
  const handleSearchBar = (e) => {
    e.preventDefault();
    handleSearchResults();
  };

  // Search for blog by category
  const handleSearchResults = () => {
    const allBlogs = blogs;
    const filteredBlogs = allBlogs.filter((blog) =>
      blog.category.toLowerCase().includes(searchKey.toLowerCase().trim())
    );
    setBlogs(filteredBlogs);
  };

  // Clear search and show all blogs
  const handleClearSearch = () => {
    setBlogs(blogs);
    setSearchKey('');
  };

  return (
    <div>
      {/* Page Header */}
      <section data-id="portfolio">
        <div className="border-block-top-110"></div>
        <div className="section-inner">
          <div className="section-title-block">
            <div className="section-title-wrapper">
              <h2 className="section-title">BLOGS</h2>
              <h5 className="section-description">My Best Works</h5>
            </div>
          </div>
      {/* Search Bar */}
      {/* <SearchBar
        value={searchKey}
        clearSearch={handleClearSearch}
        formSubmit={handleSearchBar}
        handleSearchKey={(e) => setSearchKey(e.target.value)}
      /> */}

      {/* Blog List & Empty View */}

      {!blogs.length ? <EmptyList /> : <BlogList blogs={blogs} />}
      </div>
      </section>
    </div>
  );
};

export default BlogHome;
