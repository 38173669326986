import UserData from "./UserData";
import Testimonials from './Testimonials/Testimonials';
// import Counter from './Counter/Counter';
import Skills from './Skills';
import ClientSlider from './ClientSlider/ClientSlider';

const About = ()=>{
    return(
        <>
           <section data-id="about_me">
                <div className="border-block-top-110"></div>
                <div className="section-inner">
                    <div className="section-title-block">
                        <div className="section-title-wrapper clearfix">
                            <h2 className="section-title">About Me</h2>
                            <h5 className="section-description">Magento 2 Developer</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-3 subpage-block">
                            <div className="my-photo-block tilt-effect">
                            <img src="images/photo.jpeg" alt=""/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-5 role-content">
                            <h3>Expert Magento 2 with E-Commerce Website Development</h3>
                            <p>I have been working on,</p>
                            <p>- Magento2</p>
                            <p>- WordPress</p>
                            <p>- Symfony</p>
                            <p>- Laravel</p>
                            <p>- CodeIgniter</p>
                            <p>- ReactJS</p>
                            <p>My role is involved in complete end-to-end development and management of website development projects and I am expecting opportunities to build websites with well standards.</p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-4 subpage-block">
                            <ul className="info-list">
                            {/* <li><span className="title">Age</span><span className="value">{UserData.Age}</span></li> */}
                            <li><span className="ci-icon"><i className="pe-7s-icon pe-7s-map-marker"></i></span><span className="value">{UserData.Country}</span></li>
                            {/* <li><span className="title">Address</span><span className="value">{UserData.Address}</span></li> */}
                            <li><span className="ci-icon"><i className="pe-7s-icon pe-7s-mail"></i></span><span className="value"><span className="__cf_email__" data-cfemail="b4d1d9d5ddd8f4d1ccd5d9c4d8d19ad7dbd9">{UserData.Email}</span></span>
                            </li>
                            <li><span className="ci-icon"><i className="pe-7s-icon pe-7s-call"></i></span><span className="value">{UserData.Phone}</span></li>
                            <li><img src='images/portfolio1/main/skype.png' alt="Portfolio Images"/><span className="value">{UserData.SkypeId}</span></li>
                            <li><span className="ci-icon"><i className="pe-7s-icon pe-7s-check"></i></span><span className="value available">Available For Freelance</span></li>
                            </ul>
                            <ul className="social-links">
                            <li>
                                <a className="tip social-button" href="https://www.facebook.com/kailashchandraprogrammer/" target="_blank"  rel="noreferrer" title="Facebook">
                                <i className="fa fa-facebook"></i></a>
                            </li>
                            <li>
                                <a className="tip social-button" href="https://www.linkedin.com/in/kailashprogrammer/" target="_blank"  rel="noreferrer" title="Linkdin">
                                <i className="fa fa-linkedin"></i></a>
                            </li>    
                            <li>
                                <a className="tip social-button" href="https://twitter.com/chandracs353" target="_blank"  rel="noreferrer" title="Twitter">
                                <i className="fa fa-twitter"></i></a>
                            </li>
                            <li>
                                <a className="tip social-button" href="https://github.com/kailash-programmer" target="_blank"  rel="noreferrer" title="Github">
                                <i className="fa fa-github"></i></a>
                            </li>
                            </ul>
                        </div>
                    </div>
                 
                    <div className="row">
                        <div className="col-sm-12 col-md-12 subpage-block">
                            <div className="block-title">
                            <h3>Skills & expertise</h3>
                            </div>
                            <Skills/>
                        </div>
                    </div>    
                    

                    <div className="block-title">
                        <h3>Services</h3>
                    </div>
                    <div className="servicesblock">
                      <ul class="d-flex pt-0 list-unstyled flex-wrap-wrap">
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                Magento Website Development
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                WordPress Website Development
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                Rest Api Development
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                Soap Api Development
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                Online Store Development
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                Dynamic Website Development
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                E-Commerce Website Development
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                CMS Web Development
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                PHP Development
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                Web Portal Development
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                Open Source Web Development
                                </span>
                            </span>
                            </li>
                        </ul>    
                    </div>
                    <div className="row">
                        <div className="ol-sm-12 col-md-12 subpage-block">
                            <div className="block-title">
                                <h3>Clients</h3>
                            </div>
                            <ClientSlider/>
                        </div>
                    </div>

                        
                    <div className="row">
                        <div className="col-sm-12 col-md-12 subpage-block">
                            <div className="block-title">
                            <h3>Happy Clients</h3>
                            </div>
                            <Testimonials/>
                        </div>
                    </div>
                    {/* <div className="block-title">
                        <h3>Fun Facts</h3>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-3 subpage-block">
                            <div className="fun-fact-block gray-bg tilt-effect">
                            <i className="pe-7s-icon pe-7s-smile"></i>
                            <h4>Happy Clients</h4>
                            {/* <span className="fun-value">{UserData.Client}</span> */}
                            {/* <span className="fun-value">
                                <CountUp
                                    end={UserData.Client}
                                    duration={2}
                                    />
                            </span>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 subpage-block tilt-effect">
                            <div className="fun-fact-block">
                            <i className="pe-7s-icon pe-7s-alarm"></i>
                            <h4>Working Hours</h4>
                            <span className="fun-value">
                                <CountUp
                                    end={UserData.WorkingHours}
                                    duration={2}
                                    />
                            </span>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 subpage-block tilt-effect">
                            <div className="fun-fact-block gray-bg">
                            <i className="pe-7s-icon pe-7s-medal"></i>
                            <h4>Awards Won</h4>
                            <span className="fun-value">
                                <CountUp
                                    end={UserData.WonAward}
                                    duration={2}
                                    />
                            </span>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 subpage-block tilt-effect">
                            <div className="fun-fact-block">
                            <i className="pe-7s-icon pe-7s-coffee"></i>
                            <h4>Coffee Consumed</h4>
                            <span className="fun-value">
                                <CountUp
                                    end={1200}
                                    duration={2}
                                    />
                            </span>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section> 
        </>
    )
}

export default About;