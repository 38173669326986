import React from 'react';
import { Link } from 'react-router-dom';
import Chip from '../../../common/Chip';
import './styles.css';

const BlogItem = ({
  blog: {
    title, 
    publishedAt,
    excerpt,
    id,
  },
}) => {
  return (
    
    <div className='blogItem-wrap'>
           <Link className='blogItem-link' to={`${process.env.PUBLIC_URL}/singleblog/${id}`}>
            <img className='blogItem-cover' src='../images/blog1/blog.jpeg'  alt='covedcfdr' />
            </Link>
      <Chip label='Magento 2' />
      <Link className='blogItem-link' to={`${process.env.PUBLIC_URL}/singleblog/${id}`}><h3>{title.rendered}</h3></Link>
      <p className='blogItem-desc' dangerouslySetInnerHTML={{__html: excerpt.rendered}}></p>
      <footer>
        <div className='blogItem-author'>
          <img src='images/blog1/photo.png' alt='avatar' />
          <div>
            <h6>Kailash Chandra</h6>
            <p>{publishedAt}</p>
          </div>
        </div>
        <Link className='blogItem-link' to={`${process.env.PUBLIC_URL}/singleblog/${id}`}>
          ➝
        </Link>
      </footer>
    </div>
  );
};

export default BlogItem;
