const UserData = 
{
    Name:'Kailash Chandra',
    DevRole:'Magento 2 Developer',
    Email:'kailashprogrammer@gmail.com',
    Phone:'+91 8287876959',
    SkypeId:'chandracs.353',
    Address:'H.No. 135 Rajpuram Housing Colony, Gautam Buddha Nagar, 201009 Uttar Pradesh',
    Country:'Noida, India',
    State:'Uttar Pradesh',
    City:'Noida',
    Client:12,
    WorkingHours:2400,
    WonAward:12,
    facebookurl:'https://www.facebook.com/kailashchandraprogrammer',
    linkdinurl:'https://www.linkedin.com/in/kailashprogrammer/',
    twitterurl:'https://twitter.com/chandracs353',
    Age:32,
    HighEducation:'B.Tech(CS)',
    University:'Dr. A.P.J. Abdul Kalam Technical University',
    PassYear:'2012',
    Specialization:'Magento 2, MySQL, PostgreSQL, ReactJS, React Native, Cordova, Perfect CRM,  JavaScript, JQuery, Laravel, Codeigniter, PHP, Wordpress, HTML 5, CSS 3, Bootstrap 5, Social Network API Integration, Rest API Integration, Payment Gateway Integration, Scrapper, Charts Integration, Git/Bitbucket, Site Deployment and Maintenance',
    Experience:10,
    // Skills:'Magento 2, MySQL, PostgreSQL, ReactJS, React Native, Cordova, Perfect CRM,  JavaScript, JQuery, Laravel, Codeigniter, PHP, Wordpress, HTML 5, CSS 3, Bootstrap 5, Social Network API Integration, Rest API Integration, Payment Gateway Integration, Scrapper, Charts Integration, Git/Bitbucket, Site Deployment and Maintenance',
}

export default UserData;