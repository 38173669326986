import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import '../Portfolio1.css'; 

const TabTwo = [
    {
        image: 'samsung-brandcart',
        bigImage: '../images/portfolio1/main/dp-portfolio-samsung-brandcart.png',
        category: 'Magento',
        websiteName:'SAMSUNG BRANDCART',
        title: 'An ecommerce website',
        siteurl:'https://samsung.brandcart.co.ke/'
    },
    {
        image: 'thewindowdepot',
        bigImage: '../images/portfolio1/main/dp-portfolio-thewindowdepot.png',
        category: 'Magento',
        websiteName:'THE WINDOW DEPOT',
        title: 'An ecommerce website',
		siteurl:'https://thewindowdepot.com/'
    },
    {
        image: 'pyurvana',
        bigImage: '../images/portfolio1/main/dp-portfolio-pyurvana.png',
        category: 'Magento',
        title: 'An ecommerce website',
        websiteName:'PYURVANA',
		siteurl:'https://pyurvana.com/'
    },
    {
        image: 'deptstore',
        bigImage: '../images/portfolio1/main/dp-portfolio-deptstore.png',
        category: 'Magento',
        title: 'An ecommerce website',
        websiteName:'DEPTSTORE',
		siteurl:'http://dept.store/'
    },
    {
        image: 'khaleejtools',
        bigImage: '../images/portfolio1/main/dp-portfolio-khaleejtools.png',
        category: 'Magento',
        websiteName:'KHALEEJTOOLS',
        title: 'An ecommerce website',
		siteurl:'https://www.khaleejtools.com/'
    },
    {
        image: 'fabercart',
        bigImage: '../images/portfolio1/main/dp-portfolio-fabercart.png',
        category: 'Magento',
        websiteName:'FABERCART',
        title: 'An ecommerce website',
		siteurl:'https://faberindia.com/'
    },
    {
        image: 'cuffsnlashes',
        bigImage: '../images/portfolio1/main/dp-portfolio-cuffsnlashes.png',
        category: 'Magento',
        title: 'An ecommerce website',
        websiteName:'CUFFSNLASHES',
		siteurl:'https://cuffsnlashes.com/'
    },
   
]

class Magento2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab2: 0,
            isOpen: false,
        };
    }
    render() {
        const { tab2, isOpen } = this.state;
        return (
           <>
           <div className="row row--35">
                        {TabTwo.map((value , index) => (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabTwo[tab2].bigImage}
                                        nextSrc={TabTwo[(tab2 + 1) % TabTwo.length]}
                                        prevSrc={TabTwo[(tab2 + TabTwo.length - 1) % TabTwo.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab2: (tab2 + TabTwo.length - 1) % TabTwo.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab2: (tab2 + 1) % TabTwo.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab2: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnails">
                                                    <a href="#portfolio-details">
                                                        <img src={`../images/portfolio1/main/dp-portfolio-${value.image}.png`} alt="Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                <p><a href={value.siteurl} target="_blank" rel="noopener noreferrer">{value.websiteName}</a></p>
                                                    <h4>{value.title}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
           </>
        )
    }
}
export default Magento2;