import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {Link} from 'react-router-dom';
const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

const ClientSlider = ()=>{
    return(
        <>
            <Carousel 
                responsive={responsive}
                swipeable={false}
                draggable={false}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition="all 2.5"
                transitionDuration={2000}
                removeArrowOnDeviceType={["tablet", "mobile", 'desktop']}
                >
                <div className="client-block">
                    <Link to="/" target="_blank"><img src="images/clients/faber.png" alt="clientimage"/></Link>
                </div>
                <div className="client-block">
                    <Link to="/" target="_blank"><img src="images/clients/fashionzapp.png" alt="clientimage"/></Link>
                </div>
                <div className="client-block">
                    <Link to="/" target="_blank"><img src="images/clients/cuffsnlashes.png" alt="clientimage"/></Link>
                </div>
                <div className="client-block">
                    <Link to="/" target="_blank"><img src="images/clients/khaleejtools.png" alt="clientimage"/></Link>
                </div>
                <div className="client-block">
                    <Link to="/" target="_blank"><img src="images/clients/balajimariline.png" alt="clientimage"/></Link>
                </div>
				<div className="client-block">
					<Link to="/" target="_blank"><img src="images/clients/cashew.png" alt="clientimage"/></Link>
				</div>
				<div className="client-block">
					<Link to="/" target="_blank"><img src="images/clients/dhenu.png" alt="clientimage"/></Link>
				</div>
				<div className="client-block">
					<Link to="/" target="_blank"><img src="images/clients/mensfitclub.png" alt="clientimage"/></Link>
				</div>
				<div className="client-block">
					<Link to="/" target="_blank"><img src="images/clients/moms.png" alt="clientimage"/></Link>
				</div>
				<div className="client-block">
					<Link to="/" target="_blank"><img src="images/clients/pscs.png" alt="clientimage"/></Link>
				</div>
				<div className="client-block">
					<Link to="/" target="_blank"><img src="images/clients/wecharge.png" alt="clientimage"/></Link>
				</div>
				<div className="client-block">
					<Link to="/" target="_blank"><img src="images/clients/tipster.png" alt="clientimage"/></Link>
				</div>
				<div className="client-block">
					<Link to="/" target="_blank"><img src="images/clients/trueopinion.png" alt="clientimage"/></Link>
				</div>
				<div className="client-block">
					<Link to="/" target="_blank"><img src="images/clients/ellasbubbles.png" alt="clientimage"/></Link>
				</div>
            </Carousel>
        </>
    )
}
export default ClientSlider;