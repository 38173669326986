const Exprance = (props)=>{
    return(
        <>
            <div className="timeline-event te-primary">
            <h5 className="event-date">{`${props.ExFrom} - ${props.ExTo}`}</h5>
            <h4 className="event-name">{props.ExpranceIn}</h4>
            <span className="event-description">{props.ExWhere}</span>
            <p>{props.ExDisc}</p>
            </div>
        </>
    )
}

export default Exprance;

