import React, {useState} from  'react';
import background from './../components/bg.png';
 
const UserForm = ()=>{

    const [Name, setName] = useState('');
    const [DevRole, setDevRole] = useState('');
    const [Email, setEmail] = useState('');
    const [SkypeId, setSkypeId] = useState('');
    const [Address, setAddress] = useState('');
    const [Country, setCountry] = useState('');
    const [State, setState] = useState('');
    const [City, setCity] = useState('');
    const [Client, setClient] = useState('');
    const [WorkingHours, setWorkingHours] = useState('');
    const [WonAward, setWonAward] = useState('');
    const [Age, setAge] = useState('');
    const [HighEducation, setHighEducation] = useState('');
    const [Univercity, setUnivercity] = useState('');
    const [PassYear, setPassYear] = useState('');
    const [Specialization, setSpecialization] = useState('');
    const [Exprance, setExprance] = useState('');
    const [Skills, setSkills] = useState('');

    const [isNameError, setisNameError] = useState(false);
    const [isDevRoleError, setisDevRoleError] = useState(false);
    const [isEmailError, setisEmailError] = useState(false);
    const [isSkypeIdError, setisSkypeIdError] = useState(false);
    const [isAddressError, setisAddressError] = useState(false);
    const [isCountryError, setisCountryError] = useState(false);
    const [isStateError, setisStateError] = useState(false);
    const [isCityError, setisCityError] = useState(false);
    const [isClientError, setisClientError] = useState(false);
    const [isWorkingHoursError, setisWorkingHoursError] = useState(false);
    const [isWonAwardError, setisWonAwardError] = useState(false);
    const [isAgeError, setisAgeError] = useState(false); 
    const [isHighEducationError, setisHighEducationError] = useState(false);
    const [isUnivercityError, setisUnivercityError] = useState(false);
    const [isPassYearError, setisPassYearError] = useState(false);
    const [isSpecializationError, setisSpecializationError] = useState(false);
    const [isExpranceError, setisExpranceError] = useState(false);
    const [isSkillsError, setisSkillsError] = useState(false);
    
    
    const onchangeHandler = (fieldName, value)=>{
        if(fieldName==='Name'){
            setName(value);
        }
        else if(fieldName==='DevRole'){
            setDevRole(value);
        }
        else if(fieldName==='Email'){
            setEmail(value);
        }
        else if(fieldName==='SkypeId'){
            setSkypeId(value);
        }
        else if(fieldName==='Address'){
            setAddress(value);
        }
        else if(fieldName==='Country'){
            setCountry(value);
        }
        else if(fieldName==='State'){
            setState(value);
        }
        else if(fieldName==='City'){
            setCity(value);
        }
        else if(fieldName==='Client'){
            setClient(value);
        }
        else if(fieldName==='WorkingHours'){
            setWorkingHours(value);
        }
        else if(fieldName==='WonAward'){
            setWonAward(value);
        }
        else if(fieldName==='Age'){
            setAge(value);
        } 
        else if(fieldName==='HighEducation'){
            setHighEducation(value);
        }
        else if(fieldName==='Univercity'){
            setUnivercity(value);
        }
        else if(fieldName==='PassYear'){
            setPassYear(value);
        }
        else if(fieldName==='Specialization'){
            setSpecialization(value);
        }
        else if(fieldName==='Exprance'){
            setExprance(value);
        }
        else if(fieldName==='Skills'){
            setSkills(value);
        }
    }

    const onSubmitHandler = (e)=>{
        e.preventDefault();

        if(Name.trim()===""){
            setisNameError(true);
        }
        else if(DevRole.trim()===""){
            setisDevRoleError(true);
        }
        else if(Email.trim()===""){
            setisEmailError(true);
        }
        else if(SkypeId.trim()===""){
            setisSkypeIdError(true);
        }
        else if(Address.trim()===""){
            setisAddressError(true);
        }
        else if(Country.trim()===""){
            setisCountryError(true);
        }
        else if(State.trim()===""){
            setisStateError(true);
        }
        else if(City.trim()===""){
            setisCityError(true);
        }
        else if(Client.trim()===""){
            setisClientError(true);
        }
        else if(WorkingHours.trim()===""){
            setisWorkingHoursError(true);
        }
        else if(WonAward.trim()===""){
            setisWonAwardError(true);
        }
        else if(Age.trim()===""){
            setisAgeError(true);
        }
        else if(HighEducation.trim()===""){
            setisHighEducationError(true);
        }
        else if(PassYear.trim()===""){
            setisPassYearError(true);
        }
        else if(Univercity.trim()===""){
            setisUnivercityError(true);
        }
        else if(Specialization.trim()===""){
            setisSpecializationError(true);
        }
        else if(Exprance.trim()===""){
            setisExpranceError(true);
        }
        else if(Skills.trim()===""){
            setisSkillsError(true);
        }
       
        else{
            alert('all data is full filled');
            setName('');
            setisNameError(false);
            setDevRole('');
            setisDevRoleError(false);
            setEmail('');
            setisEmailError(false);
            setSkypeId('');
            setisSkypeIdError(false);
            setAddress('');
            setisAddressError(false);
            setCountry('');
            setisCountryError(false);
            setState('');
            setisStateError(false);
            setCity('');
            setisCityError(false);
            setClient('');
            setisClientError(false);
            setWorkingHours('');
            setisWorkingHoursError(false);
            setWonAward('');
            setisWonAwardError(false);
            setAge('');
            setisAgeError(false);
            setHighEducation('');
            setisHighEducationError(false);
            setUnivercity('');
            setisUnivercityError(false);
            setPassYear('');
            setisPassYearError(false);
            setSpecialization('');
            setisSpecializationError(false);
            setExprance('');
            setisExpranceError(false);   
            setSkills('');
            setisSkillsError(false);
        }
    }
    

    return(
        <>
            <section className="userform_section" style={{backgroundImage:`url(${background})`}}>
                <form className="userform" onSubmit={(e)=>{onSubmitHandler(e)}}>
                    <div className="row">
                        <div className="col-md-6">
                            <h3 className="subtitle">personal detail</h3>
                            <hr className="subtitle_hr"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>full Name</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    placeholder="Enter your name"
                                    value={Name}
                                    onChange={(e)=>{onchangeHandler('Name', e.target.value)}}
                                />
                                {isNameError && <div className="error">Name is require</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>devlopment role</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    placeholder="Eg. webdevloper"
                                    value={DevRole}
                                    onChange={(e)=>{onchangeHandler('DevRole', e.target.value)}}
                                />
                                {isDevRoleError && <div className="error">Devloper Role is Require</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Your Email</label>
                                <input
                                    type="email"
                                    className="form-control" 
                                    value={Email}
                                    onChange={(e)=>{onchangeHandler('Email', e.target.value)}}
                                    
                                    placeholder="Enter email"
                                />
                                {isEmailError && <div className="error">Email is require</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">    
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Your skype id</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={SkypeId}
                                    onChange={(e)=>{onchangeHandler('SkypeId', e.target.value)}}
                                    
                                    placeholder="Enter skype id"
                                />
                                {isSkypeIdError && <div className="error">SkypeId is require</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>Your address</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={Address}
                                    onChange={(e)=>{onchangeHandler('Address', e.target.value)}}
                                    
                                    placeholder="Enter address"
                                />
                                {isAddressError && <div className="error">Address is require</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>Your Country</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={Country}
                                    onChange={(e)=>{onchangeHandler('Country', e.target.value)}}
                                    
                                    placeholder="Enter Country"
                                />
                                {isCountryError && <div className="error">Country is require</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">    
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>Your state</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={State}
                                    onChange={(e)=>{onchangeHandler('State', e.target.value)}}
                                    
                                    placeholder="Enter state"
                                />
                                {isStateError && <div className="error">State is require</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>Your city</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={City}
                                    onChange={(e)=>{onchangeHandler('City', e.target.value)}}
                                    
                                    placeholder="Enter city"
                                />
                                {isCityError && <div className="error">City is require</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>happy client</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={Client}
                                    onChange={(e)=>{onchangeHandler('Client', e.target.value)}}
                                    
                                    placeholder="Happy Client Eg. 103"
                                />
                                {isClientError && <div className="error">client is required is require</div>}
                            </div>
                        </div>
                    </div>   
                    <div className="row"> 
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>total working hours</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={WorkingHours}
                                    onChange={(e)=>{onchangeHandler('WorkingHours', e.target.value)}}
                                    
                                    placeholder="working hours"
                                />
                                {isWorkingHoursError && <div className="error">working Hour is required</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>total awards won</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={WonAward}
                                    onChange={(e)=>{onchangeHandler('WonAward', e.target.value)}}
                                    
                                    placeholder="awards won"
                                />
                                {isWonAwardError && <div className="error">award won is required</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>Your Age</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={Age}
                                    onChange={(e)=>{onchangeHandler('Age', e.target.value)}}
                                    
                                    placeholder="Enter your Age"
                                />
                                {isAgeError && <div className="error">Age is required</div>}
                            </div>
                        </div>
                    </div>

                    <div className="row pt-5">
                        <div className="col-md-6">
                            <h3 className="subtitle">for your resume</h3>
                            <hr className="subtitle_hr"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>your higher education</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={HighEducation}
                                    onChange={(e)=>{onchangeHandler('HighEducation', e.target.value)}}
                                    
                                    placeholder="Enter  higher education"
                                />
                                {isHighEducationError && <div className="error">Resume is required</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>passout year</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={PassYear}
                                    onChange={(e)=>{onchangeHandler('PassYear', e.target.value)}}
                                    
                                    placeholder="passout year"
                                />
                                {isPassYearError && <div className="error">PassYear is require</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>college/ univercity</label>
                                <input
                                    type="email"
                                    className="form-control" 
                                    value={Univercity}
                                    onChange={(e)=>{onchangeHandler('Univercity', e.target.value)}}
                                    
                                    placeholder="college/ univercity"
                                />
                                {isUnivercityError && <div className="error">Univercity is require</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">    
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>any Specialization</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={Specialization}
                                    onChange={(e)=>{onchangeHandler('Specialization', e.target.value)}}
                                    
                                    placeholder="Eg. React"
                                />
                                {isSpecializationError && <div className="error">Specialization is required</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>exprance</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={Exprance}
                                    onChange={(e)=>{onchangeHandler('Exprance', e.target.value)}}
                                    
                                    placeholder="Exprance"
                                />
                                {isExpranceError && <div className="error">Exprance is require</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>add skills</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={Skills}
                                    onChange={(e)=>{onchangeHandler('Skills', e.target.value)}}
                                    
                                    placeholder="add skills"
                                />
                                {isSkillsError && <div className="error">Skills is required</div>}
                            </div>
                        </div>
                    </div>   
                    <div className="row"> 
                        <div className="col-md-4">
                            <label for="file-upload" className="custom-file-upload">
                                upload your cv
                            </label> 
                            <input id="file-upload" type="file"/>
                        </div>
                        <div className="col-md-4">
                            <label for="file-upload" className="custom-file-upload">
                                Upload Your Profil 
                            </label> 
                            <input id="file-upload" type="file"/>
                        </div>
                        <div className="col-md-4">
                            <button type="submit" className="submitBtn">Button</button>
                        </div>
                    </div>
                </form>
            </section>
        </>
    )
}

export default UserForm;



