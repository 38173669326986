const Education = (props)=>{
    return(
        <>
            <div className="timeline-event te-primary">
                <h5 className="event-date">{props.EduPassYear}</h5>
                <h4 className="event-name">{props.CoursName}</h4>
                <span className="event-description">{props.Univercity}</span>
                <p>{props.EduDisc}</p>
            </div>
        </>
    )
}
export default Education