import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import UserData from "./UserData";																					
import Whatsapp from "./Whatsapp";																					

const {Name} = UserData; 
const Header = ()=>{
	// const [showSidebar, setShowSidebar] = useState(false);

	const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return(
        <>
            <header id="site_header" className={`menu-links ${isOpen ? "header mobile-menu-hide header-color-light" : "header header-color-light"}`}>
                <div className="my-photo tilt-effect">
					<img src={process.env.PUBLIC_URL + '/images/photo.jpeg'} alt="profileimage"/>
                </div>
                <div className="site-title-block">
					<h1 className="site-title">{Name}</h1>
                </div>
                <div className="site-nav">
					<ul id="nav" className="site-main-menu">
						{/* <li>
							<NavLink className="pt-trigger" activeClassName="is-active" to={`${process.env.PUBLIC_URL}/kailashchandra`} exact>
							<i className="menu-icon pe-7s-icon pe-7s-home"></i>
								Home
							</NavLink>
						</li> */}
						<li className="dropdown">
							<NavLink className="pt-trigger" onClick={toggle} activeClassName="is-active" to={`${process.env.PUBLIC_URL}/`} exact>
								<i className="menu-icon pe-7s-icon pe-7s-user"></i>
								About me
							</NavLink>
							{/*<Link className="addLink" to={`${process.env.PUBLIC_URL}/aboutform`}>
								<i className="menu-icon pe-7s-icon pe-7s-plus"></i>
							</Link> */}
						</li>
						<li>
							<NavLink className="pt-trigger" onClick={toggle} activeClassName="is-active"  to={`${process.env.PUBLIC_URL}/resume`} exact>
							<i className="menu-icon pe-7s-icon pe-7s-id"></i>
								Resume
							</NavLink>
							{/*<Link className="addLink" to={`${process.env.PUBLIC_URL}/resumeform`}>
								<i className="menu-icon pe-7s-icon pe-7s-plus"></i>
							</Link> */}
						</li>
						<li>
							<NavLink className="pt-trigger" onClick={toggle} activeClassName="is-active" to={`${process.env.PUBLIC_URL}/portfolio`} exact>
							<i className="menu-icon pe-7s-icon pe-7s-portfolio"></i>
								Portfolio
							</NavLink>
							{/*<Link className="addLink" to={`${process.env.PUBLIC_URL}/portfolioform`}>
								<i className="menu-icon pe-7s-icon pe-7s-plus"></i>
							</Link> */}
						</li>
						<li>
							<NavLink className="pt-trigger" onClick={toggle} activeClassName="is-active" to={`${process.env.PUBLIC_URL}/blog`} exact>
							<i className="menu-icon pe-7s-icon pe-7s-portfolio"></i>
								Blog
							</NavLink>
							{/*<Link className="addLink" to={`${process.env.PUBLIC_URL}/blogform`}>
								<i className="menu-icon pe-7s-icon pe-7s-plus"></i>
							</Link> */}
						</li>
						<li>
							<NavLink className="pt-trigger" onClick={toggle} activeClassName="is-active" to={`${process.env.PUBLIC_URL}/contact`} exact>
							<i className="menu-icon pe-7s-icon pe-7s-mail"></i>
								Contact
							</NavLink>
						</li>
						{/* <li>
							<NavLink className="pt-trigger" activeClassName="is-active" to="/userform" exact>
							<i className="menu-icon pe-7s-icon pe-7s-mail"></i>
								userform
							</NavLink>
						</li> */}
					</ul>
                </div>
            </header>
            <div className="mobile-header mobile-visible">
                <div className="mobile-logo-container">
                    <div className="mobile-site-title"><img src="images/headerphoto.png" alt="Kailash Chandra" width="200" style={{marginBottom : 10}} /></div>
                </div>
                <span className="menu-toggle mobile-visible" onClick={toggle}><i className="fa fa-bars"></i></span>
            </div>
			<Whatsapp />
        </>
    )
}

export default Header;



