const ScillBar = (props)=>{
    return(
        <>
            <h4>{props.skill}</h4>                               
            <div className="skill-container">
                <div className="skill-percentage skill-1 " style={{width:`${props.skillperc}`}}></div>
            </div>
        </>
    )
}
export default ScillBar;