import React, {useState} from  'react';
import background from './../components/bg.png';
 
const PortfolioForm = ()=>{

    const [Project, setProject] = useState();
    const [selectedFile, setSelectedFile] = useState();

    //const PorfolioData =[{Project:Project, ProjectImg:ProjectImg, selectedFile:selectedFile}];

    const [isProjectErr, setisProjectErr] = useState(false);

    const onchangeHandler = (fieldName, value)=>{
        if(fieldName ==="project"){
            setProject(value);
        }    
    }

    const imageHandler = (event)=>{
        setSelectedFile(event.target.files[0]);
    }

    const SubmitHandler = (event)=>{
        event.preventDefault();
        console.log(selectedFile,'image name');
    }
    
    const onSubmitHandler = (e)=>{
        e.preventDefault();

        if(Project.trim()===""){
            setisProjectErr(true);
        }
        else{
            alert(Project);
        }   
    }

    return(
        <>
            <section className="userform_section" style={{backgroundImage:`url(${background})`}}>
                <form className="userform" onSubmit={(e)=>{onSubmitHandler(e)}}>
                    <div className="row">
                        <div className="col-md-6">
                            <h3 className="subtitle">portfolio form</h3>
                            <hr className="subtitle_hr"/>
                        </div>
                    </div>
                    
                    <div className="row"> 
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Project Name</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={Project}
                                    onChange={(e)=>{onchangeHandler('project', e.target.value)}}
                                    placeholder="Project Name"
                                />
                                {isProjectErr && <div className="error">Project Name is Require</div>}
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <label>total awards won</label>
                            <div className='d-flex form-group p-relative'>
                                <input type="file" name="file"  className="form-control" value={selectedFile}  onChange={imageHandler} />
                                <div>
                                    
                                    <button className='addimgbtn' onClick={SubmitHandler}>
                                        <i className="menu-icon pe-7s-icon pe-7s-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label></label>
                            <button type="submit" className="submitBtn">Button</button>
                        </div>
                    </div>
                </form>
            </section>
        </>
    )
}

export default PortfolioForm;



