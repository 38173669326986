import React, {Component} from 'react';

class Contact extends Component {
    state = {
        name: '',
        email: '',
        phone: '',
        message: '',
        emailStatus: ''
    };

    // handle the value
    handleCHange = input => e => {
        this.setState({[input]: e.target.value});
    }

    // when submit btn is clicked
    submitForm = (e) => {
        const {name, email, phone, message} = this.state;


        // create a new XMLHttpRequest
        var xhr = new XMLHttpRequest();
    
        // get a callback when the server responds
        xhr.addEventListener('load', () => {
            // update the response state and the step
            
            this.setState ({
                emailStatus: xhr.responseText
            });
        });
        // open the request with the verb and the url
        xhr.open('GET', 'https://www.kailashchandra.com/sendemail/index.php?sendto=' + email + 
                                '&name=' + name + 
                                '&phone=' + phone +
                                '&message=' + message);
        // send the request
        xhr.send();

        // reset the fields
        this.setState({
            name: '',
            email: '',
            phone: '',
            message: ''
        });
        e.preventDefault();
    }

    render(){
        const {name, email, phone, message, emailStatus} = this.state;
        return(
             <>
                         <section data-id="contact">
              <div className="border-block-top-110"></div>
              <div className="section-inner">
                <div className="section-title-block">
                  <div className="section-title-wrapper">
                    <h2 className="section-title">Contact</h2>
                    <h5 className="section-description">Get in Touch</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-md-6 subpage-block">
                    <div className="block-title">
                      <h3>Get in Touch</h3>
                    </div>
                    <p>Please contact us using the form and we’ll get back to you as soon as possible.

</p>
                    <div className="contact-info-block">
                      <div className="ci-icon">
                        <i className="pe-7s-icon pe-7s-map-marker"></i>
                      </div>
                      <div className="ci-text">
                        <h5>INDIA</h5>
                      </div>
                    </div>
                    <div className="contact-info-block">
                      <div className="ci-icon">
                        <i className="pe-7s-icon pe-7s-mail"></i>
                      </div>
                      <div className="ci-text">
                        <h5>
                          <span className="__cf_email__">kailashprogrammer@gmail.com</span>
                        </h5>
                      </div>
                    </div>
                    <div className="contact-info-block">
                      <div className="ci-icon">
                        <i className="pe-7s-icon pe-7s-call"></i>
                      </div>
                      <div className="ci-text">
                        <h5>+91 8287876959</h5>
                      </div>
                    </div>
                    <div className="contact-info-block">
                      <div className="ci-icon">
                        <i className="pe-7s-icon pe-7s-check"></i>
                      </div>
                      <div className="ci-text">
                        <h5>Freelance Available</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 subpage-block">
                    <div className="block-title">
                      <h3>Contact Form</h3>
                    </div>

            <form onSubmit={this.submitForm}>
                      <div className="controls">
                        <div className="form-group">
                            <input
                              type="text"
                              value={name} onChange={this.handleCHange('name')}
                              className="form-control"
                              placeholder="Full Name" 
                              autoComplete='off'
                            />
                        </div>

                        <div className="form-group">
                            <input
                              type="email"
                              value={email} onChange={this.handleCHange('email')}
                              className="form-control"
                              placeholder="Email Address"
                              autoComplete='off'
                            />
                        </div>

                        <div className="form-group">
                            <input
                              type="phone"
                              value={phone} onChange={this.handleCHange('phone')}
                              className="form-control"
                              placeholder="Phone Number"
                              autoComplete='off'
                              />
                        </div>

                        <div className="form-group">
                            <textarea
                              value={message} onChange={this.handleCHange('message')}
                              className="form-control"
                              placeholder="Message for Me"
                              rows="4"
                              autoComplete='off'
                              ></textarea>
                        </div>

                        <div className="form-group bt1">
                          <input type="submit" className="btn-raised btn-primary" value="SEND" /> 
                          {emailStatus ? emailStatus : null}
                        </div>
                      </div>
                    </form>





            </div>
                </div>
              </div>
            </section>
            </>
        );
    }
}

export default Contact;