import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Testimonials = ()=>{
    return(
        <>
            <Carousel
                showArrows={true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                autoPlay={true}
                interval={6100}
            >
                <div className="Testimonials">
                    <img src="images/testimonials/no-image.jpg" alt="clients" />
                    <div className="myCarousel">
                        <h3>RICHARD D.</h3>
                        <h4>Tipster Challenge</h4>
                        <p>"Kailash has worked with me on various projects and has always exceeded my expectations. Work always completed in a timely manner. Professional work, and very communicative. I highly recommend Kailash for your future project work."</p>
                    </div>
                </div>
                <div>
                <img src="images/testimonials/no-image.jpg" alt="clients" />
                    <div className="myCarousel">
                        <h3>Mayank Kumar</h3>
                        <h4>eVanik Networks</h4>
                        <p>"Kailash has demonstrated extraordinary brilliance while working with eVanik Networks. His knack for understanding the problem and solving it with out of the box thinking is what is really expected out of great engineers. I wish him all the best in his future endeavours."</p>
                    </div>
                </div>
                <div>
                <img src="images/testimonials/no-image.jpg" alt="clients" />
                    <div className="myCarousel">
                        <h3>RICHARD D.</h3>
                        <h4>PSCS</h4>
                        <p>"Kailash has worked with me on various projects and has always exceeded my expectations. Work always completed in a timely manner. Professional work, and very communicative. I highly recommend Kailash for your future project work."</p>
                    </div>
                </div>
            </Carousel>
        </>
    )
}

export default Testimonials;