import UserData from "./UserData";
import ScillBar from './SkillBar';
import Education from './Education';
import Exprance from './Exprance';

const skill = [
  {skill:'HTML', skillperc:'85%'},
  {skill:'Css', skillperc:'80%'}, 
  {skill:'Bootstrap', skillperc:'80%'}, 
  {skill:'React', skillperc:'80%'}, 
  {skill:'Sass', skillperc:'77%'}, 
  {skill:'Less', skillperc:'76%'}, 
];
 const codeSkill = [
  {skill:'PHP 7', skillperc:'90%'},
  {skill:'Javascript', skillperc:'80%'},
  {skill:'JQuery', skillperc:'80%'},
  {skill:'AJAX', skillperc:'76%'},
  {skill:'JSON', skillperc:'80%'},
  {skill:'React Native', skillperc:'70%'},
  {skill:'Laravel', skillperc:'70%'},
  {skill:'Cake PHP', skillperc:'78%'},
  {skill:'Codeigniter (MVC, HMVC)', skillperc:'75%'}, 
  {skill:'Smarty', skillperc:'70%'}, 
  {skill:'Custom Framework\'s', skillperc:'80%'}, 
  {skill:'Magento 2', skillperc:'80%'}, 
  {skill:'Wordpress', skillperc:'80%'}, 
  {skill:'Interspire', skillperc:'76%'}, 
  {skill:'Payment Gateway (Razorpay, Paypal, Authorize.net, Stripe, Ccavenue, PayUMoney, Checkout, Sudopay, 2co, custom payment gateway and zombaio payment gateway)', skillperc:'70%'}, 
  {skill:'MySQL', skillperc:'90%'}, 
  {skill:'PgSQL', skillperc:'75%'}, 
  {skill:'Mongodb', skillperc:'70%'}, 
  {skill:'LAMP', skillperc:'80%'}, 
  {skill:'XAMPP', skillperc:'90%'}, 
  {skill:'WAMP', skillperc:'90%'}, 
  {skill:'GitHub', skillperc:'90%'}, 
  {skill:'Bitbucket', skillperc:'90%'}, 
 ];

 const EducationData = [
  {EduPassYear:2012, CoursName:'B.Tech.', Univercity:'Dr. A.P.J. Abdul Kalam Technical University'},
  {EduPassYear:2007, CoursName:'Senior Secondary School (SSC)', Univercity:'State Board'},
  {EduPassYear:2005, CoursName:'Higher Secondary School (HSC)', Univercity:'State Board'}
  ];

const ExpranceData = [
  {ExFrom:'Aug 2017', ExTo:'Current', ExperienceIn:'TEAM LEAD (Magento 2 Developer)', ExWhere:'Plux Infosystems Private Limited', ExDisc:''},
  {ExFrom:'Jan 2015', ExTo:'Aug 2017', ExperienceIn:'Sr. Software Engineer', ExWhere:'Evanik Networks Private Limited', ExDisc:''},
  {ExFrom:'July 2014', ExTo:'Jan 2015', ExperienceIn:'Sr. Software Engineer', ExWhere:'White Pixelz Private Limited', ExDisc:''},
  {ExFrom:'March 2014', ExTo:'July 2014', ExperienceIn:'Jr. Software Engineer', ExWhere:'Dimension India Network Private Limited', ExDisc:''},
  {ExFrom:'July 2012', ExTo:'March 2014', ExperienceIn:'Web Developer', ExWhere:'Freelance (Upwork)', ExDisc:''}
];

const Resume = ()=>{
    return(
        <>
            <section  data-id="resume">
              <div className="border-block-top-110"></div>
              <div className="section-inner">
                <div className="section-title-block">
                  <div className="section-title-wrapper">
                    <h2 className="section-title">Resume</h2>
                    <h5 className="section-description">{UserData.Exprance} Years of Experience</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-md-4 subpage-block">
                    <div className="block-title">
                      <h3>Education</h3>
                    </div>
                    <div className="timeline">
                     {
                        EducationData.map((val, index)=>{
                          return(
                            <>
                              <Education
                                key={index}
                                EduPassYear={val.EduPassYear}
                                CoursName={val.CoursName}
                                Univercity={val.Univercity}
                              />
                            </>
                          )
                        })
                     }
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 subpage-block">
                    <div className="block-title">
                      <h3>Experience</h3>
                    </div>
                    <div className="timeline">
                     {
                       ExpranceData.map((val, i)=>{
                         return(
                           <>
                              <Exprance
                                key={i}
                                ExFrom={val.ExFrom}
                                ExTo={val.ExTo}
                                ExpranceIn={val.ExperienceIn}
                                ExWhere={val.ExWhere}
                                ExDisc={val.ExDisc}
                              />
                           </>
                         )
                       })
                     }
                       
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 subpage-block">
                    <div className="block-title">
                      <h3>Design Skills</h3>
                    </div>
                    <div className="skills-info">
                       {
                        skill.map((val, k)=>{
                          return(
                            <>
                              <ScillBar
                                key={k}
                                skill={val.skill}
                                skillperc={val.skillperc}
                              />
                            </>
                          )
                        })
                      } 
                    </div>
                    <div className="block-title">
                      <h3>Coding Skills</h3>
                    </div>
                    <div className="skills-info">
                      {
                        codeSkill.map((val, ke)=>{
                          return(
                            <>
                              <ScillBar
                                key={ke}
                                skill={val.skill}
                                skillperc={val.skillperc}
                              />
                            </>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-sm-12 col-md-12">
                    <div className="download-cv-block">
                      <a className="button" target="_blank" href="#">Download CV</a>
                    </div>
                  </div>
                </div> */}
              </div>
            </section>
        </>
    )
}

export default Resume;