import React from "react";

import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const Whatsapp = () => {
	return (
		<WhatsAppWidget
			phoneNo="918287876959"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={false}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt=""
			iconSize="50"
			iconColor="white"
			iconBgColor="#00e676"
			headerIcon="images/blog1/photo.png"
			headerIconColor="pink"
			headerTxtColor="white"
			headerBgColor="#00e676"
			headerTitle="Kailash Chandra"
			headerCaption="Online"
			bodyBgColor="#bbb"
			chatPersonName="Support"
			chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
			footerBgColor="#999"
			btnBgColor="#deb823"
			btnTxtColor="white"
			btnTxt="Start Chat"
		/>
	);
};

export default Whatsapp;