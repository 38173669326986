import Header from "./components/Header";
// import Home from './components/Home';
import About from './components/About';
import Resume from './components/Resume';
import Portfolio from './components/Portfolio';
// import Blog from './components/Blog';
import Contact from './components/Contact';
import UserForm from './userPanel/UserForm';
import AboutForm from './userPanel/AboutForm';
import ResumeForm from './userPanel/ResumeForm';
import PortfolioForm from './userPanel/PortfolioForm';
import BlogForm from './userPanel/BlogForm';
import Blog from './pages/Blog';
import BlogHome from './pages/BlogHome';
import Portfolioprojects from './components/portfolio/Portfolioprojects';
import Magento2 from './components/portfolio/Magento2';
import Php from './components/portfolio/Php';
import Laravel from './components/portfolio/Laravel';
import Codeigniter from './components/portfolio/Codeigniter';
import Wordpress from './components/portfolio/Wordpress';
import {Routes, Route} from "react-router-dom";

const App = ()=>{
    return(
        <> 
            <div id="page" className="page">
                <Header/>
                <div id="main" className="site-main">
                    <div className="pt-wrapper">


                        <Routes>
                            <Route path="/" element={ <About/> } />
                            <Route path="/kailashchandra" element={ <About/> } />
                            <Route path="/resume" element={ <Resume/> } />
                           <Route path="/portfolio" element={<Portfolio/>}>
                                
                               <Route path="/portfolio" element={<Portfolioprojects/>} />
                                <Route path="/portfolio/magento2" element={<Magento2/>} />
                               <Route path="/portfolio/php" element={<Php/>} />
                               <Route path="/portfolio/laravel" element={<Laravel/>} />
                               <Route path="/portfolio/codeigniter" element={<Codeigniter/>} />
                               <Route path="/portfolio/wordpress" element={<Wordpress/>} /> 
                            </Route>
   

                            <Route path="/blog" element={ <BlogHome/> } />
                            <Route path="/singleblog/:id" element={ <Blog/> } />
                            <Route path="/contact" element={ <Contact/> } />
                            <Route path="/userform" element={ <UserForm/> } />
                            <Route path="/aboutform" element={ <AboutForm/> } />
                            <Route path="/resumeform" element={ <ResumeForm/> } />
                            <Route path="/portfolioform" element={ <PortfolioForm/> } />
                            <Route path="/blogform" element={ <BlogForm/> } /> 
                        </Routes>
                    </div>    
                </div>
            </div>
            
        </>
        
    )
}

export default App;