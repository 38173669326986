import React from 'react';
import './Skills.css';

const Skills = () => {
  return (
       <>
          <section>
              <div className='skills'>
                <div class="mt-10">
                    <div>
                        <h4 class="skills-group-list-title mb-0">
                            E-Commerce Development Deliverables
                        </h4>
                        <ul class="d-flex pt-0 list-unstyled flex-wrap-wrap">
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    API Integration
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Website Migration
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Software Maintenance
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Design Enhancement
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Bug Fix
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Theme Customization
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Responsive Design
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    WordPress Plugin
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Theme Development
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Website Customization
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Website Optimization
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Platform Plugin
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Web Design
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    App Development
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    E-Commerce Website
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Landing Page
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Templates
                                </span>
                            </span>
                            </li>
                        </ul>
                    </div>
                    
                </div>
                <div class="mt-10">
                    <div>
                        <h4 class="skills-group-list-title mb-0">
                            E-Commerce Platforms
                        </h4>
                        <ul class="d-flex pt-0 list-unstyled flex-wrap-wrap">
                           <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Magento
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    WordPress
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    WooCommerce
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Elementor
                                </span>
                            </span>
                            </li>
                        </ul>
                    </div>
                    
                </div>
                <div class="mt-10">
                    <div>
                        <h4 class="skills-group-list-title mb-0">
                            E-Commerce Development Skills
                        </h4>
                        <ul class="d-flex pt-0 list-unstyled flex-wrap-wrap">
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Magento2
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Symfony
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                  Laravel
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                  CodeIgniter
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                   Smarty
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                   PHP
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                   React
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                   KnockoutJs
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                   Jquery
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Javascript
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Bootstrap
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    HTML
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Css
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                   Sass
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                   Less
                                </span>
                            </span>
                            </li>
                           
                        </ul>
                    </div>
                    
                </div>
                <div class="mt-10">
                    <div>
                        <h4 class="skills-group-list-title mb-0">
                            Databases
                        </h4>
                        <ul class="d-flex pt-0 list-unstyled flex-wrap-wrap">
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    PostgreSQL
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    MariaDB
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    MongoDB
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    MySQL
                                </span>
                            </span>
                            </li>
                        </ul>
                    </div>
                    
                </div>
                <div class="mt-10">
                    <div>
                        <h4 class="skills-group-list-title mb-0">
                            Payment Systems
                        </h4>
                        <ul class="d-flex pt-0 list-unstyled flex-wrap-wrap">
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Authorize.Net
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    2Checkout
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    PayPal
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Stripe
                                </span>
                            </span>
                            </li>
                            <li class="mb-0">
                            <span>
                                <span class="cs-skill-badge">
                                    Ccavenue
                                </span>
                            </span>
                            </li>
                        </ul>
                    </div>
                    
                </div>
                </div>
          </section>
       </>
  );
};

export default Skills;