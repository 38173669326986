import React, {Component} from  'react';
import background from './../components/bg.png';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
 
export default class BlogForm extends Component {

  state = {
    editorState: EditorState.createEmpty(),
  }
  onEditorStateChange = (editorState) => {
    this.setState({ 
      editorState,
    });
  };

  render (){
    const {editorState} = this.state;
    return (
      <>
      <section className="userform_section" style={{backgroundImage:`url(${background})`}}>
      <form className="userform">
          <div className="row">
              <div className="col-md-6">
                  <h3 className="subtitle">Blog form</h3>
                  <hr className="subtitle_hr"/>
              </div>
          </div>
          
          <div className="row"> 
              <div className="col-md-10">
                  <div className="form-group">
                      <label>Title</label>
                      <input
                          type="text"
                          className="form-control" 
                          value=""
                          placeholder="Title Name"
                      />
                  </div>
              </div> 
          </div>
          <div className="row"> 
              <div className="col-md-12">
              <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={this.onEditorStateChange}
        />
              </div> 
          </div>
          <div className="row">
              <div className="col-md-4">
                      <label></label>
                      <input  type="submit" className="submitBtn" name="submit" value="Submit"/>
              </div>
          </div>    
      </form>
  </section>
  </>
    )
  }
}




