import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import '../Portfolio1.css'; 

const TabFour = [
    {
        image: 'true-opinion',
        bigImage: '../images/portfolio1/main/dp-portfolio-true-opinion.png',
        category: 'Laravel',
        websiteName:'TRUE-OPINION',
        title: 'This is an PAID SURVEY WEBSITE with admin panel (Laravel)',
		siteurl:'https://www.true-opinion.com/'
    },
]

class Laravel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab4: 0,
            isOpen: false,
        };
    }
    render() {
        const { tab4, isOpen } = this.state;
        return (
           <>
           <div className="row row--35">
                        {TabFour.map((value , index) => (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabFour[tab4].bigImage}
                                        nextSrc={TabFour[(tab4 + 1) % TabFour.length]}
                                        prevSrc={TabFour[(tab4 + TabFour.length - 1) % TabFour.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabFour.length - 1) % TabFour.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabFour.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnails">
                                                    <a href="#portfolio-details">
                                                        <img src={`../images/portfolio1/main/dp-portfolio-${value.image}.png`} alt="Portfolio Images"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                <p><a href={value.siteurl} target="_blank" rel="noopener noreferrer">{value.websiteName}</a></p>
                                                    <h4>{value.title}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
           </>
        )
    }
}
export default Laravel;