import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
// import { blogList } from '../../config/data';
// import Chip from '../../components/common/Chip';
import EmptyList from '../../components/common/EmptyList';
import './styles.css';
import { Link } from 'react-router-dom';

const Blog = () => {
  const api = "https://api.pluxinfosystems.com/wp-json/wp/v2/posts";
  //const [page, setPage] = React.useState<number>(0);
  const [myData1, setMyData1] = useState([]);
  const { id } = useParams();
  const getApiDataDetailsfull = async () => {
        const res = await axios.get(`${api}/${id}`);
        setMyData1(res.data);
    };

    useEffect(() => {
      getApiDataDetailsfull();
    }, []);
 
  return (
    <>
      <section data-id="portfolio">
        <div className="border-block-top-110"></div>
      <Link className='blog-goBack' to='/blog'>
        <span> &#8592;</span> <span>Go Back</span>
      </Link>
     
        <div className='blog-wrap'>
          <header>
          <h1>{myData1?.['title']?.['rendered']}</h1>
          </header>
          <img src='../images/blog1/blog.jpeg' alt="Blog Images" />
          <div className='blog-desc' dangerouslySetInnerHTML={{__html: myData1?.['content']?.['rendered']}}></div><br /><br/>
          <p className='blog-date'>Published {myData1?.['date']}</p>
          {/* <div className='blog-subCategory'>
              {blog.subCategory.map((category, i) => (
                <div key={i}>
                  <Chip label={category} />
                </div>
              ))}
            </div> */}
        </div>
     </section> 
    </>
  );
};

export default Blog;
