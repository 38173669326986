import React, {useState} from  'react';
import background from './../components/bg.png';
 
const ResumeForm = ()=>{

    
    const [HighEducation, setHighEducation] = useState('');
    const [Univercity, setUnivercity] = useState('');
    const [PassYear, setPassYear] = useState('');
    const [ExpranceFrom, setExpranceFrom] = useState('');
    const [ExpranceTo, setExpranceTo] = useState('');
    const [ExpranceOnTech, setExpranceOnTech] = useState('');
    const [ExpranceInComp, setExpranceInComp] = useState('');
    const [AboutExprance, setAboutExprance] = useState('');
    const [Skills, setSkills] = useState('');
    const [SkillsLabel, setSkillsLabel] = useState('');
    
    const resumeData = {HighEducation:HighEducation, Univercity:Univercity, PassYear:PassYear, ExpranceFrom:ExpranceFrom, ExpranceTo:ExpranceTo, ExpranceOnTech:ExpranceOnTech,ExpranceInComp:ExpranceInComp,AboutExprance:AboutExprance,Skills:Skills, SkillsLabel:SkillsLabel}

    const [isHighEducationError, setisHighEducationError] = useState(false);
    const [isUnivercityError, setisUnivercityError] = useState(false);
    const [isPassYearError, setisPassYearError] = useState(false);
    const [isExpranceFromError, setisExpranceFromError] = useState(false);
    const [isExpranceToError, setisExpranceToError] = useState(false);
    const [isExpranceOnTechError, setisExpranceOnTechError] = useState(false);
    const [isExpranceInCompError, setisExpranceInCompError] = useState(false);
    const [isAboutExpranceError, setisAboutExpranceError] = useState(false);
    const [isSkillsError, setisSkillsError] = useState(false);
    const [isSkillsLabelError, setisSkillsLabelError] = useState('');
    
    
    const onchangeHandler = (fieldName, value)=>{
        
        if(fieldName==='HighEducation'){
            setHighEducation(value);
        }
        else if(fieldName==='Univercity'){
            setUnivercity(value);
        }
        else if(fieldName==='PassYear'){
            setPassYear(value);
        }
        
        else if(fieldName==='ExpranceFrom'){
            setExpranceFrom(value);
        }
        else if(fieldName==='ExpranceTo'){
            setExpranceTo(value);
        }
        else if(fieldName==='ExpranceOnTech'){
            setExpranceOnTech(value);
        }
        else if(fieldName==='ExpranceInComp'){
            setExpranceInComp(value);
        }
        else if(fieldName==='AboutExprance'){
            setAboutExprance(value);
        }
        else if(fieldName==='Skills'){
            setSkills(value);
        }
        else if(fieldName==='SkillsLabel'){
            setSkillsLabel(value);
        }
    }

    const onSubmitHandler = (e)=>{
        e.preventDefault();

       if(HighEducation.trim()===""){
            setisHighEducationError(true);
        }
        else if(PassYear.trim()===""){
            setisPassYearError(true);
        }
        else if(Univercity.trim()===""){
            setisUnivercityError(true);
        }
        else if(ExpranceFrom.trim()===""){
            setisExpranceFromError(true);
        }
        else if(ExpranceTo.trim()===""){
            setisExpranceToError(true);
        }

        else if(ExpranceOnTech.trim()===""){
            setisExpranceOnTechError(true);
        }
        else if(ExpranceInComp.trim()===""){
            setisExpranceInCompError(true);
        }
        else if(AboutExprance.trim()===""){
            setisAboutExpranceError(true);
        }
        else if(Skills.trim()===""){
            setisSkillsError(true);
        }
        else if(SkillsLabel.trim()===""){
            setisSkillsLabelError(true);
        }
       
        else{
            alert(resumeData);
            console.log(resumeData);            
            setHighEducation('');
            setisHighEducationError(false);
            setUnivercity('');
            setisUnivercityError(false);
            setPassYear('');
            setisPassYearError('');
            setExpranceFrom('');  
            setisExpranceFromError(false);
            setExpranceTo('');
            setisExpranceToError(false);
            setExpranceOnTech('');
            setisExpranceOnTechError(false);
            setExpranceInComp('');
            setisExpranceInCompError(false);
            setAboutExprance('');
            setisAboutExpranceError(false);

            setSkills('');
            setisSkillsError(false);
            setSkillsLabel('');
            setisSkillsLabelError(false);

        }
    }
    

    return(
        <>
            <section className="userform_section" style={{backgroundImage:`url(${background})`}}>
                <form className="userform" onSubmit={(e)=>{onSubmitHandler(e)}}>
                    <div className="row pt-5">
                        <div className="col-md-6">
                            <h3 className="subtitle">for your resume</h3>
                            <hr className="subtitle_hr"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>your higher education</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={HighEducation}
                                    onChange={(e)=>{onchangeHandler('HighEducation', e.target.value)}}
                                    
                                    placeholder="Enter  higher education"
                                />
                                {isHighEducationError && <div className="error">Education is required</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>passout year</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={PassYear}
                                    onChange={(e)=>{onchangeHandler('PassYear', e.target.value)}}
                                    
                                    placeholder="passout year"
                                />
                                {isPassYearError && <div className="error">PassYear is require</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>college/ univercity</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={Univercity}
                                    onChange={(e)=>{onchangeHandler('Univercity', e.target.value)}}
                                    
                                    placeholder="college/ univercity"
                                />
                                {isUnivercityError && <div className="error">Univercity is require</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">    
                        
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>exprance from </label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={ExpranceFrom}
                                    onChange={(e)=>{onchangeHandler('ExpranceFrom', e.target.value)}}
                                    
                                    placeholder="Exprance from"
                                />
                                {isExpranceFromError && <div className="error">Exprance is require</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>exprance to</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={ExpranceTo}
                                    onChange={(e)=>{onchangeHandler('ExpranceTo', e.target.value)}}
                                    
                                    placeholder="Exprance To"
                                />
                                {isExpranceToError && <div className="error">Exprance is require</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>exprance on tech </label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={ExpranceOnTech}
                                    onChange={(e)=>{onchangeHandler('ExpranceOnTech', e.target.value)}}
                                    
                                    placeholder="Exprance On Technology"
                                />
                                {isExpranceOnTechError && <div className="error">Exprance is require</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">    
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>exprance in company </label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={ExpranceInComp}
                                    onChange={(e)=>{onchangeHandler('ExpranceInComp', e.target.value)}}
                                    
                                    placeholder="Exprance In Company"
                                />
                                {isExpranceInCompError && <div className="error">Exprance is require</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>About your Exprance </label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={AboutExprance}
                                    onChange={(e)=>{onchangeHandler('AboutExprance', e.target.value)}}
                                    
                                    placeholder="About Your Exprance"
                                />
                                {isAboutExpranceError && <div className="error">Exprance is require</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>add skills</label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    value={Skills}
                                    onChange={(e)=>{onchangeHandler('Skills', e.target.value)}}
                                    
                                    placeholder="Add Your Skills"
                                />
                                {isSkillsError && <div className="error">Skills is required</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">    
                        <div className="col-md-4">
                            <div className="form-group">
                            <label>skill Label</label>
                                <input
                                    type="number"
                                    className="form-control" 
                                    value={SkillsLabel}
                                    onChange={(e)=>{onchangeHandler('SkillsLabel', e.target.value)}}
                                    
                                    placeholder="Skills How You Know"
                                />
                                {isSkillsLabelError && <div className="error">Skills Label  is required</div>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label></label>
                            <button type="submit" className="submitBtn">Button</button>
                        </div>
                    </div>   
                </form>
            </section>
        </>
    )
}

export default ResumeForm;



